// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/LoggedInPageSections/LocationSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/LoggedInPageSections/LocationSection.tsx");
  import.meta.hot.lastModified = "1720025378288.9622";
}
// REMIX HMR END

import { Location } from '../Location';
import { ScrollableHorizontalBox } from '~common/components/scroll/ScrollableHorizontalBox';
export function LocationSection({
  locations
}) {
  const filteredLocations = locations.filter(location => !!location.address);
  if (!filteredLocations.length) {
    return null;
  }
  return <section aria-labelledby="locations" className="mt-8 px-4 xl:max-w-7xl xl:mx-auto xl:px-8">
      <div className="mt-16 flow-root sm:px-4">
        <p className="text-dark-blue font-bold text-left">Locations</p>
      </div>
      <ScrollableHorizontalBox hideScrollbar={true}>
        {filteredLocations.map(location => <Location label={location.label} address={location.address} key={location.id} />)}
      </ScrollableHorizontalBox>
    </section>;
}
_c = LocationSection;
var _c;
$RefreshReg$(_c, "LocationSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;