// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"src/components/LoggedInPageSections/NewsLetterSection.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "src/components/LoggedInPageSections/NewsLetterSection.tsx");
  import.meta.hot.lastModified = "1726684194661.5457";
}
// REMIX HMR END

import { ArrowLongRightIcon } from '@heroicons/react/24/outline';
import { Button } from '~ui/Button';
import { Input } from '~ui/Input';
export function NewsLetterSection() {
  return <section aria-labelledby="choosecategory-section" className="pt-24 sm:pt-32 xl:max-w-7xl xl:mx-auto xl:px-8">
      <div className="dark:bg-slate-900 px-4 sm:px-6 lg:px-8 xl:px-8 bg-gray-50 py-12 px-4 rounded-3xl flex flex-col items-start space-y-4 mx-4 lg:space-y-0 lg:flex-row lg:justify-between">
        <p className="dark:text-slate-400  text-black text-3xl font-bold">
          Sign up for our newsletter.
        </p>
        <div className="flex flex-col justify-start items-start space-y-4 lg:space-y-0 lg:flex-row lg:space-x-2">
          <Input type="email" name="email-address" id="email-address" autoComplete="email" placeholder="Enter your email" className="rounded-full w-60 py-3" required />
          <Button className="bg-red-500 rounded-full text-white px-4 py-2 flex justify-between items-center hover:bg-red-600 focus:ring-2 focus:ring-offset-1 focus:ring-red-700" variant="unstyled">
            Subscribe
            <ArrowLongRightIcon color="white" className="w-4 h-4 ml-2" />
          </Button>
        </div>
      </div>
    </section>;
}
_c = NewsLetterSection;
var _c;
$RefreshReg$(_c, "NewsLetterSection");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;